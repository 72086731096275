import React from 'react';
import Page from '../components/Page';
import { graphql, Link } from 'gatsby';
import BlogCard from '../components/Blog/BlogCard';
import { GatsbyImage } from 'gatsby-plugin-image';
import waves from '../images/waves.svg';

export default function Index({ data }) {
  const { posts } = data.blog;

  return (
    <>
      <Page
        title={'Naturlabbet - Friluftsliv, natur, vandring'}
        description="Friluftsliv, natur & vandring - Naturlabbet förvandlar dina äventyr med unika guider, tips och inspiration."
      >
        <section className="relative isolate overflow-hidden bg-white">
          <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">


              <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Din guide till friluftsliv och naturupplevelser
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Välkommen till Naturlabbet, din plats till ett storslaget friluftslivet! Här hittar du en skattkista av inspirerande guider, råd och praktiska tips för att förvandla dina äventyr till oförglömliga upplevelser.

              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <Link
                  to="/blogg/"
                  className="rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Senaste artiklar
                </Link>
                <Link to="/vandringstips/" className="text-sm font-semibold leading-6 text-gray-900">
                  Vandringstips <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
            <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
              <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                <GatsbyImage
                  image={data.image.childImageSharp.gatsbyImageData}
                  alt="vandring"

                  className="w-[76rem] rounded-md shadow-2xl"
                />
              </div>
            </div>
          </div>
        </section>

        <div style={{ backgroundImage: `url(${waves})` }} />
        <section class="bg-lime-50">
          <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 mb-5">
            <div class="gap-8 items-center mb-10 py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
              <GatsbyImage
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="hem"
                className="w-full rounded-lg"
              />
              <div class="mt-4 md:mt-0">
                <h2 class="mb-4 text-3xl tracking-tight font-bold text-gray-900 ">
                  Upptäck vår fantastiska natur
                </h2>
                <p class="mb-6 font-light text-gray-500 md:text-lg ">
                  Här på Naturlabbet hittar du guider relaterat till vandring
                  och friluftslivet i Sverige, tips för utrustning och klädsel,
                  information om nyttiga växter och mycket annat. Naturlabbet är
                  för alla som vill upptäcka och njuta av vår fantastiska
                  svenska natur!
                </p>
              </div>
            </div>
            <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 px-4 lg:px-6">
              <div>
                <h3 class="mb-2 text-xl font-bold ">Utrustning</h3>
                <p class="text-gray-500 ">
                  Vi sammanställer guider och tips för utrustning som är
                  användbar i friluftslivet. Här samlar vi även
                  produktrecenssioner och tester som vi själva utför!
                </p>
              </div>
              <div>
                <h3 class="mb-2 text-xl font-bold ">Vandringsmat</h3>
                <p class="text-gray-500 ">
                  Vi ger tips och inspiration om vad man kan tillaga och ha med
                  sig för mat ut på vandring eller övernattar i naturen.
                </p>
              </div>
              <div>
                <h3 class="mb-2 text-xl font-bold ">Tips</h3>
                <p class="text-gray-500 ">
                  Hos oss hittar du också en mängd nyttiga artiklar som ger svar
                  på vanliga frågor om vandring, friluftslivet och naturen!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container px-4 py-8 mx-auto max-w-screen-xl lg:py-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
            Senaste artiklar
          </h2>
          <div className="blog-grid">
            {posts &&
              posts.map((post) => {
                return (
                  <BlogCard
                    key={post.id}
                    title={post.frontmatter.title}
                    description={post.excerpt}
                    category={post.frontmatter.category}
                    link={'/' + post.frontmatter.category + post.fields.slug}
                    image={post.frontmatter?.coverImage}
                  />
                );
              })}
          </div>
        </section>

        <br />
        <br />
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query home {
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(/blog/)/" } }
      limit: 3
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          category
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
        id
        excerpt(pruneLength: 120)
      }
    }
    image: file(relativePath: { eq: "klader-vandring.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "home.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
